import './App.css';
import React, { useEffect, useState } from 'react';

const WS_URL = 'ws://ws.clarkington.com:8234'
const ws = new WebSocket(WS_URL);

function App() {

  const [time, setTime] = useState("");
  const [echo, setEcho] = useState("");
  const [message, setMessage] = useState("");

  useEffect(
    () => {   
      ws.onmessage = function(event) {
        try {
          const json = JSON.parse(event.data);
          if ((json.type === "time")) {
            console.log("The time is " + json.content);
            const date = new Date(json.content * 1000);
            setTime(date.toLocaleTimeString());
          }
          else if ((json.type === "echo")) {
            console.log("echo " + json.content);
            setEcho(json.content);
          }
        }
        catch (err) {
          console.error(err);
        }
      }
    }, [ws]);
  

  const sendMessage = () => {
    ws.send(message);
  };

  return (
    <div className="App">
      <h1>Send Message</h1>
      <input 
        placeholder='Message...' 
        onChange={e => {
          setMessage(e.target.value);
        }} />
      <button onClick={sendMessage}>Send Message</button>
      <h1>Message Received</h1>
      {echo}
      <h1>Current Time</h1>
      {time}
    </div>
  );
}

export default App;
